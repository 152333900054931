import { createTheme } from '@mui/material';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0d6739',
      contrastText: 'white',
    },
    secondary: {
      main: '#0d6739',
    },
  },
  typography: {
    fontFamily: 'sans-serif',
    fontSize: 15,
  },
});

export default theme;
